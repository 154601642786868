import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import Loading from './Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Alert = () => {
    const alert = useSelector((state: any) => state.alert)
    useEffect(() => {
        if (alert.error) {
            toast.error(alert.error, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (alert.success) {
            toast.success(alert.success, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [alert]);
    return (
        <div className='alert-container'>

            {alert.loading && <Loading />}
            <ToastContainer />

            {/* {
                alert?.error && (
                    saberToast.error({
                        title: 'Error',
                        text: alert.error,
                        delay: 200,
                        duration: 2000,
                        position: "top-right"
                    })
                )
            }
            {
                alert?.success && (
                    saberToast.success({
                        title: 'success',
                        text: alert.success,
                        delay: 200,
                        duration: 200000,
                        position: "top-right"
                    })
                )
            } */}
        </div>
    )
}



export default Alert
