import React from 'react'
import {
    Box,
} from '@mui/material';
import AddTask from './AddTask';

export default function Form() {
    return (
        <Box p={4} maxWidth="md" mx="auto" className="no-scrollbar">
            <AddTask />
        </Box>
    );
}
