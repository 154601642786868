export const statusArray: any = [
    {
        id: 1,
        status: "Pending"
    },
    {
        id: 2,
        status: "Completed"
    }
]

export const scheduleTaskStatusArray: any = [
    {
        id: 1,
        status: "Assigned"
    },
    {
        id: 2,
        status: "Completed"
    }
]

export const answeStyleVar: any = {
    text: 0,
    doc: 1,
}