import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import History from '../pages/History'
import Feedback from '../pages/Feedback'
import ScheduleTask from '../pages/ScheduleTask'
import ScheduleTaskHistory from '../pages/ScheduleTaskHistory'



function PrivateRoutes() {
    return (

        <Routes >
            <Route path='' element={<Home />} />
            <Route path='history' element={<History />} />
            <Route path='feedback/:docId' element={<Feedback />} />
            <Route path='schedule-task' element={<ScheduleTask />} />
            <Route path='schedule-task-history' element={<ScheduleTaskHistory />} />
            {/* <Route path='/plans' element={<Plans />} /> */}
            {/* <Route path='/paypalCheckout' element={<Paypal />} /> */}
        </Routes>
    )
}

export default PrivateRoutes
