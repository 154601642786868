import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert } from '../../redux/alert/alertSlice';
import { create_folder_thunk, get_folders_thunk } from '../../redux/folder/folderThunk';
import { create_doc_thunk } from '../../redux/doc/docThunk';
import { create_email_thunk, get_emails_thunk } from '../../redux/email/emailThunk';

export default function AddTask() {
    const token = localStorage.getItem('s3WebToken');
    const [person, setPerson]: any = useState(null);
    const [newPerson, setNewPerson] = useState('');
    const [email, setEmail]: any = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [deadline, setDeadline] = useState('');
    // const [tasks, setTasks] = useState([
    //     { id: 1, title: 'Task 1', personName: 'Person A', deadline: '2024-05-30', description: 'Task 1 Description', status: 'Pending' },
    //     { id: 2, title: 'Task 2', personName: 'Person B', deadline: '2024-06-15', description: 'Task 2 Description', status: 'Approved' },
    // ]);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const minDate = tomorrow.toISOString().split('T')[0];


    const personsFolder = useSelector((state: any) => state.folder.data)
    const emails = useSelector((state: any) => state.email.data)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(get_folders_thunk({ token }))
    }, [dispatch, token]);
    useEffect(() => {
        dispatch(get_emails_thunk({ token }))
    }, [dispatch, token]);

    const handleSubmit = () => {
        const newTask: any = {
            title: taskTitle,
            email: email.email,
            folder: person,
            deadline: deadline,
            description: taskDescription,
        };

        // setTaskTitle('');
        // setTaskDescription('');
        // setDeadline('');
        dispatch(clearAlerts())

        dispatch(create_doc_thunk({ token, data: newTask }))
        setTaskTitle('');
        setEmail('');
        setTaskDescription('');
        setDeadline('');
        setPerson(null);
    };

    const handleNewPersonCreation = () => {

        if (!newPerson) {
            dispatch(setErrorAlert('Please enter a person name'))
            return
        }
        dispatch(clearAlerts())
        // setPerson(newPerson);
        dispatch(create_folder_thunk({ token, folder: newPerson }))
        setNewPerson('');
    };

    const handleNewEmailCreation = () => {

        if (!newEmail) {
            dispatch(setErrorAlert('Please enter a email'))
            return
        }
        dispatch(clearAlerts())
        dispatch(create_email_thunk({ token, email: newEmail }))
        setNewEmail('');
    };
    return (
        <Paper elevation={3} sx={{ p: 4 }} >

            <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter a Person Name"
                        value={newPerson}
                        onChange={e => setNewPerson(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') handleNewPersonCreation();
                        }}
                    />
                </Grid>
                <Grid item sm={3}>
                    <Button
                        className='h-[100%]'
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleNewPersonCreation}
                    >
                        Create New Person
                    </Button>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter a Email"
                        value={newEmail}
                        onChange={e => setNewEmail(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') handleNewEmailCreation();
                        }}
                    />
                </Grid>
                <Grid item sm={3}>
                    <Button
                        className='h-[100%]'
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleNewEmailCreation}
                    >
                        Create New Email
                    </Button>
                </Grid>
                <Grid item xs={12} className='text-xl mt-[20px]'>
                    Task Assignment Form
                </Grid>
                {/* <Typography variant="h5" gutterBottom>
                    Task Assignment Form
                </Typography> */}
                <Grid item xs={12} >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Select A Person</InputLabel>
                        <Select
                            value={person ? person.folderId : ''}
                            onChange={(e) => {
                                const selectedFolderId = e.target.value;
                                const selectedPerson = personsFolder.find((p: any) => p.folderId === selectedFolderId);
                                setPerson(selectedPerson || null); // Update the entire person object or reset if none selected
                            }}
                            label="Select A Person"
                        >

                            {personsFolder?.length > 0 && personsFolder.map((p: any) => (
                                <MenuItem key={p.folderId} value={p.folderId}>
                                    {p.folder} {/* Display folder name */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Select A Email</InputLabel>
                        <Select
                            value={email ? email._id : ''}
                            onChange={(e) => {
                                const selectedEmailId = e.target.value;
                                console.log('selectedEmailId', selectedEmailId)
                                const selectedEmail = emails.find((e: any) => e._id === selectedEmailId);
                                console.log('selectedEmail', selectedEmail)
                                setEmail(selectedEmail || null); // Update the entire person object or reset if none selected
                            }}
                            label="Select A Email"
                        >

                            {emails?.length > 0 && emails.map((e: any) => (
                                <MenuItem key={e._id} value={e._id}>
                                    {e.email} {/* Display folder name */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter a Task Title"
                        value={taskTitle}
                        onChange={e => setTaskTitle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter a Task Description"
                        multiline
                        rows={4}
                        value={taskDescription}
                        onChange={e => setTaskDescription(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Select a Deadline"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: minDate,
                        }}
                        value={deadline}
                        onChange={e => setDeadline(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Box mt={3}>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Box>
        </Paper>
    )
}
