import React, { useEffect, useState } from 'react'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { delete_doc_thunk, get_docs_thunk } from '../../redux/doc/docThunk';
import { Link } from 'react-router-dom';
import { statusArray } from '../../common/common';
import { clearAlerts } from '../../redux/alert/alertSlice';
export default function RecentTask() {
    const token = localStorage.getItem('s3WebToken');
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus]: any = useState(null);


    const dispatch = useDispatch()
    const docs: any = useSelector((state: any) => state.doc.data)
    // const handleIconClick = (url: any, event: any) => {
    //     event.stopPropagation(); // Prevent the accordion from toggling
    //     window.open(url, '_blank'); // Open the document in a new tab
    // };

    useEffect(() => {
        let statusInp = status ? status.status : statusArray[0].status
        dispatch(get_docs_thunk({ status: statusInp, token }))
    }, [status, dispatch, token]);


    const handleDelete = (id: any) => {
        dispatch(clearAlerts())
        dispatch(delete_doc_thunk({ token, docId: id }))
    };
    return (
        <Box mt={4} display="flex" justifyContent="center" style={{ overflowY: 'scroll' }}>
            <style>
                {`
          /* Hide scrollbar for Chrome, Safari and Opera */
          ::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge, and Firefox */
          body {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        `}
            </style>
            <Paper elevation={3} sx={{ p: 4, width: '80%', backgroundColor: '#f5f5f5' }}>
                <Grid container spacing={2} mb={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by person name or task title"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            sx={{ bgcolor: '#fff', borderRadius: '4px' }}
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Select A Status</InputLabel>
                            <Select
                                value={status ? status.id : statusArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const selectedStatus = statusArray.find((p: any) => p.id === id);
                                    setStatus(selectedStatus || null); // Update the entire person object or reset if none selected
                                }}
                                label="Select A Person"
                            >

                                {statusArray?.length > 0 && statusArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status} {/* Display folder name */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box mt={2}>
                    {docs?.length > 0 ? (
                        docs
                            .filter((task: any) =>
                                task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                task.folderMongoId.folder.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .map((task: any) => (

                                <Grid container spacing={2} key={task._id} alignItems="center">
                                    <Grid item xs={11.5} sx={{ mb: 2 }}>
                                        <Accordion key={task._id} >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${task._id}-content`}
                                                id={`panel${task._id}-header`}
                                            >
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Typography variant="h6">
                                                            <Link to={`https://docs.google.com/document/d/${task.docId}`} target="_blank" onClick={(e) => e.stopPropagation()}>

                                                                <span>

                                                                    {task.title}
                                                                </span>

                                                                <LaunchIcon sx={{ fontSize: '14px' }} className='ml-2' />

                                                            </Link>
                                                            <span> - </span>
                                                            <Link to={`https://drive.google.com/drive/folders/${task.folderMongoId.folderId}`} target="_blank" onClick={(e) => e.stopPropagation()}>
                                                                <span>
                                                                    {task.folderMongoId.folder}
                                                                </span>

                                                                <LaunchIcon sx={{ fontSize: '14px' }} className='ml-2' />
                                                            </Link>
                                                            {/* Icon button next to the text */}

                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {new Date(task.deadline).toLocaleDateString()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                        <IconButton onClick={(e) => { e.stopPropagation(); window.open(`/feedback/${task.docId}`, '_blank'); }}>
                                                            <FeedbackIcon color="primary" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body2" color="textSecondary">
                                                    {task.description}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={.5} style={{ textAlign: 'right' }}>
                                        <IconButton onClick={(e) => handleDelete(task._id)}>
                                            <DeleteIcon className='text-black' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            {docs === null ? (
                                <CircularProgress size={35} color="secondary" thickness={5} />
                            ) : (
                                <Typography variant="body2">No tasks found</Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    )
}
