import React from 'react'
import RecentTask from '../components/home/RecentTask'
import Header from '../components/header/Header'

export default function History() {
    return (
        <>
            <Header />
            <RecentTask />
        </>

    )
}
